body{
  margin:0;
}

.top-pane{
  background-color:cadetblue;
}

.pane{
height: 50vh;
display:flex;
}

.editor-container
{
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: rgb(122, 241, 201);
}

.editor-container.collapsed
{
  flex-grow: 0;
}

.editor-container.collapsed .CodeMirror-scroll{
  position: absolute;
}

.editor-title
{
  display: flex;
  justify-content: space-between;
  background-color:rgb(150, 36, 36);
  color: aliceblue;
  padding: .5rem .5rem .5rem 1rem;
}

.code-mirror-wrapper{
  height: 100% !important;
}

.code-mirror-wrapper
{
  flex-grow: 1;
  overflow: hidden;
}