.bdata
{
    background-color: rgb(117, 129, 139);
    display: flex;
    flex-direction: row;
    padding: 1rem;
}
.bdata button
{
    padding: 0.7rem;
    border-radius: 5px;
    border: none;
    box-shadow:2px 2px 2px black ;
    color: white;
    background-color: rgb(88, 87, 114);
    cursor: pointer;
}
button:hover
{
    background-color: #3e8e41;
}
button:active
{
    background-color: #3e8e41;
    box-shadow: none;
    transform: translateY(3px);
}